import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import CampaignCard from "../components/campaign-card";
import NewsletterSection from "../components/newsletter-section";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { ImFacebook, ImTwitter, ImYoutube } from "react-icons/im";
import { BsInstagram } from "react-icons/bs";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			LogoImg: wpMediaItem(title: { eq: "Logo-White" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			heroImg: wpMediaItem(title: { eq: "Home-Hero-Latest" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			allWpCampaign(sort: { fields: dateGmt, order: DESC }) {
				nodes {
					dateGmt
					title
					slug
					campaignFields {
						introductionParagraph
						featuredImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 50
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			allWpArticle(sort: { fields: dateGmt, order: DESC }) {
				nodes {
					title
					slug
					dateGmt
					articleFields {
						description
						url
						featuredImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: FULL_WIDTH
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const pluginImage = getImage(
		data.heroImg?.localFile.childImageSharp.gatsbyImageData
	);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title="Clean air for all | The Ella Roberta Foundation"
				description="Ella Roberta Adoo Kissi Debrah was born happy and healthy. By the age of seven she had been diagnosed with asthma. At the age of nine she had suffered a fatal asthma attack. It took her mother years of campaigning for the role of air pollution to be recognised: Ella is the first person in the world to have air pollution listed as a cause of death on her death certificate."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Home | The Ella Roberta Foundation",
					description:
						"Ella Roberta Adoo Kissi Debrah was born happy and healthy. By the age of seven she had been diagnosed with asthma. At the age of nine she had suffered a fatal asthma attack. It took her mother years of campaigning for the role of air pollution to be recognised: Ella is the first person in the world to have air pollution listed as a cause of death on her death certificate.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<BgImage
					style={{ backgroundPosition: "top" }}
					Tag="section"
					className="w-100 position-relative hero-image-home"
					id="hero-home"
					image={pluginImage}
				>
					<div
						style={{ index: 1, opacity: 0.4, backgroundColor: "black" }}
						className="w-100 h-100 start-0 top-0 position-absolute"
					></div>
					<Container style={{ zIndex: 2 }} className="h-100 position-relative ">
						<Row className="align-items-md-start align-items-center pt-md-8  h-100">
							<Col xs={12}>
								<h1 className="text-white big-text-home ">
									<span className="gobold-reg">CLEAN AIR</span>
									<br />
									FOR ALL
								</h1>
								<Button
									className="fs-5 px-4 w-100 w-md-auto mt-5 py-2"
									variant="outline-white"
									to="/about-ella"
									as={Link}
								>
									READ ELLA’S STORY
								</Button>
							</Col>
						</Row>
					</Container>
				</BgImage>
				<section className="bg-primary py-5 pt-lg-8">
					<Container>
						<Row className="justify-content-center">
							<Col lg={9}>
								<p className="text-white fs-3  text-center position-relative">
									<span
										style={{ left: "-2.6rem", top: "-2rem" }}
										className="display-2 d-none d-lg-inline-block me-5 position-absolute gobold-thin text-uppercase "
									>
										“
									</span>
									<span className="d-lg-none gobold-thin">“</span>
									We believe in a world where everyone can breathe air that is
									free from toxic pollution, regardless of where they live,
									their economic status or their ethnic background.
									<span className="d-lg-none gobold-thin">”</span>
									<span
										style={{ right: "-2.6rem", top: "-2rem" }}
										className="display-2 d-none d-lg-inline-block position-absolute gobold-thin text-uppercase"
									>
										”
									</span>
								</p>
								<p className="text-center text-white fs-5">
									Rosamund, Ella’s mum
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="bg-background py-5 pt-lg-8">
					<Container>
						<Row className="justify-content-center">
							<Col lg={9} className="text-center">
								<h2 className="fs-1 text-primary pb-5">ABOUT ELLA</h2>
								<p className="text-start text-lg-center">
									Ella Roberta Adoo Kissi Debrah was born happy and healthy. By
									the age of seven she had been diagnosed with asthma. At the
									age of nine she had suffered a fatal asthma attack. It took
									her mother years of campaigning for the role of air pollution
									to be recognised: Ella is the first person in the world to
									have air pollution listed as a cause of death on her death
									certificate.
								</p>
								<Button
									className="fs-5 px-4 w-100 w-md-auto mt-4 py-2"
									variant="outline-black"
									to="/get-involved"
									as={Link}
								>
									HOW CAN YOU HELP?
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-8 text-center">
					<Container>
						<Row>
							<Col className="pb-4">
								<h2 className="display-3 text-primary pb-5">CAMPAIGNS</h2>
								<p className="text-start text-lg-center">
									The latest news and announcements from the Foundation. Find
									out how we’ve pushed air pollution up the news agenda, and
									discover our new campaigns. From grassroots activism to
									government-level campaigning.
								</p>
							</Col>
						</Row>
						<Row>
							{data.allWpCampaign.nodes.map((campaign) => (
								<Col className="mb-5" lg={6} xl={4}>
									<CampaignCard
										image={
											campaign.campaignFields.featuredImage.localFile
												.childImageSharp.gatsbyImageData
										}
										imageAlt={campaign.campaignFields.featuredImage.altText}
										title={campaign.title}
										description={campaign.campaignFields.introductionParagraph}
										uri={`/campaigns/${campaign.slug}`}
									/>
								</Col>
							))}
						</Row>
					</Container>
				</section>
				<section className="pb-5 pb-lg-8 text-center">
					<Container>
						<Row>
							<Col className="pb-4">
								<h2 className="display-3 text-primary pb-5">LATEST NEWS</h2>
							</Col>
						</Row>
						<Row>
							{data.allWpArticle.nodes
								.filter((item, index) => index < 4)
								.map((campaign) => (
									<Col className="mb-5" lg={6} xl={6}>
										<CampaignCard
											external
											image={
												campaign.articleFields.featuredImage.localFile
													.childImageSharp.gatsbyImageData
											}
											imageAlt={campaign.articleFields.featuredImage.altText}
											title={campaign.title}
											description={campaign.articleFields.description}
											uri={campaign.articleFields.url}
										/>
									</Col>
								))}
						</Row>
					</Container>
				</section>
				{/* <section className="bg-primary py-5 pt-lg-8 text-start ">
					<Container>
						<Row>
							<Col lg={9}>
								<h2 className="display-3 text-white pb-5">GET INVOLVED</h2>
								<p className="text-white">
									There are so many ways you can help us make breathing clean
									air a human right. Whether it’s fundraising or
									awareness-raising, we welcome everything. See what people have
									done to support us in the past; from cake sales to song
									writing; making art to crowdfunding.
								</p>
								<Button
									className="fs-5 w-100 w-md-auto px-5 mt-4 py-2"
									variant="outline-white"
									to="/get-involved"
									as={Link}
								>
									INSPIRATION
								</Button>
							</Col>
						</Row>
					</Container>
				</section> */}
				{/* <section className="py-5 py-lg-8">
					<Container>
						<Row className="justify-content-center">
							<Col className="text-center" lg={10}>
								<ul className="ps-0 mx-auto mb-1 mb-5 mb-lg-8">
									<li className="d-inline-block me-3 ">
										<a
											target="_blank"
											rel="noreferrer"
											className=" display-4 social-icon"
											href="https://www.facebook.com/Ella-Roberta-Family-Foundation-229573000528354/"
										>
											<ImFacebook />
										</a>
									</li>
									<li className="d-inline-block me-3">
										<a
											target="_blank"
											rel="noreferrer"
											className=" display-4 social-icon"
											href="https://twitter.com/EllaRobertaFdn?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
										>
											<ImTwitter />
										</a>
									</li>
									<li className="d-inline-block me-3 ">
										<a
											target="_blank"
											rel="noreferrer"
											className="  display-4 social-icon"
											href="https://www.instagram.com/theellarobertafamilyfoundation/?hl=en"
										>
											<BsInstagram />
										</a>
									</li>
									<li className="d-inline-block  ">
										<a
											target="_blank"
											rel="noreferrer"
											className="  display-4 social-icon"
											href="https://www.youtube.com/channel/UCn_mfXJanHMcNUgYYvkk4bQ"
										>
											<ImYoutube />
										</a>
									</li>
								</ul>{" "}
								<TwitterTimelineEmbed
									sourceType="profile"
									screenName="EllaRobertaFdn"
									options={{ height: "100vh", width: "100%" }}
								/>
							</Col>
						</Row>
					</Container>
				</section> */}
				<NewsletterSection />
			</Layout>
		</>
	);
};

export default IndexPage;
